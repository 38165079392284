import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  VeltCommentTool,
  VeltPresence,
} from '@veltdev/react';
import { USER_ROLES } from '@/constants/roles';
import DashboardPermissionedControl from '@/components/DashboardPermissionedControl';
import { setPresenceUsers } from '@/store/slices/appSlice';
import keyBy from '@/utils/keyBy';
import { useActiveDashboard } from '@/hooks';
import NewInsightPopover from './NewInsightPopover';
import { useDashboardVeltContext } from '../DashboardVeltProvider';

import styles from './DashboardToolbar.module.scss';
import CommentsSidebar from './CommentsSidebar';
import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import tooltips from '@/utils/tooltips';

const DashboardToolbar = () => {
  const dashboard = useActiveDashboard();
  const { isCommentingToggle, isVeltInitialized } = useDashboardVeltContext();

  const dispatch = useDispatch();
  const { isDarkMode } = useSelector((state) => state.app);

  const handlePresenceUserChange = (nextUsers) => {
    const userByIds = keyBy(nextUsers, 'userId');
    dispatch(setPresenceUsers(userByIds));
  };

  return (
    <div className={styles.toolbar}>
      <div className={styles.toolbarLeft}>
        <DashboardPermissionedControl
          dashboardId={dashboard.id}
          requiredPermissions={[USER_ROLES.EDITOR]}
          hideIfUnauthorized
        >
          <NewInsightPopover />
        </DashboardPermissionedControl>
      </div>
      <div className={styles.toolbarRight}>
        <div className={styles.toolbarItem}>
          {isVeltInitialized ? (
            <VeltPresence
              flockMode
              onPresenceUserChange={handlePresenceUserChange}
              darkMode={isDarkMode}
            />
          ) : null}
        </div>
        {isVeltInitialized ? (
          <>
            <DashboardPermissionedControl
              dashboardId={dashboard.id}
              requiredPermissions={[USER_ROLES.EDITOR]}
              hideIfUnauthorized
            >
              <div className={styles.toolbarItem}>
                <TooltipInfo title={tooltips.comments.bubble}>
                  <div>
                    <VeltCommentTool onCommentModeChange={isCommentingToggle.set} darkMode={isDarkMode} />
                  </div>
                </TooltipInfo>
              </div>
            </DashboardPermissionedControl>
            <div className={styles.toolbarItem}>
              <TooltipInfo title={tooltips.comments.inbox}>
                <div>
                  <CommentsSidebar />
                </div>
              </TooltipInfo>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default DashboardToolbar;
