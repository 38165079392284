import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { Box } from '@mui/material';
import ErrorBoundary from '@/components/ErrorBoundary';
import LockerOverlay from '@/components/Locker/LockerOverlay';
import { useGetInsightQuery } from '@/store/apis/insightsApi';
import { paramNames } from '@/constants';
import serializeInsightsParams from '@/utils/serializeInsightsParams';
import PanelHeader from './PanelHeader';
import PanelContent from './PanelContent';
import PanelErrorFallback from './PanelErrorFallback';
import PanelFooter from './PanelFooter';
import { useDashboardVeltContext } from '../DashboardVeltProvider';
import { defaultPageSize } from './helpers/constants';
import getDataFromInsight from './helpers/getDataFromInsight';
import getIsInsightPanel from './helpers/getIsInsightPanel';

const PanelErrorFallbackComponent = ({ panelId, ...props }) => <PanelErrorFallback panelId={panelId} {...props} />;

const DashboardPanel = ({ dashboard, isEditingUser, panel }) => {
  const {
    panelId,
    title,
    settings: panelSettings,
    type: panelType,
    params: panelParams,
  } = panel;
  const panelRef = useRef(null);
  const { baseParams } = dashboard;

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  // TODO: Check all explore related code (should not be here)
  const [hasMoreExplorePages, setHasMoreExplorePages] = useState(false);

  const serializedInsightsParams = useMemo(() => {
    const tempParams = { ...panelParams };

    if (tempParams[paramNames.take]) {
      // Get one more record than needed to determine if there are more pages
      tempParams[paramNames.take] += 1;
    }

    return serializeInsightsParams(baseParams, tempParams);
  }, [baseParams, panelParams]);

  const isInsightPanel = getIsInsightPanel(panelType);

  const {
    data: panelData,
    isLoading,
    isFetching,
    error: panelError,
  } = useGetInsightQuery(serializedInsightsParams, {
    skip: !isInsightPanel,
  });

  // This ensure that the logo loader is shown for the first time only
  useEffect(() => {
    if (isFirstLoad && !isLoading) {
      setIsFirstLoad(false);
    }
  }, [isFirstLoad, isLoading]);

  const mappedPanelData = useMemo(
    () => getDataFromInsight({ panelParams, panelData }),
    [panelParams, panelData],
  );
  const hasMorePages = useMemo(
    () => mappedPanelData?.length > (panelParams[paramNames.take] || defaultPageSize),
    [mappedPanelData?.length, panelParams],
  );

  useEffect(() => {
    if (panel.isNew && panelRef.current) {
      panelRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [panel.isNew]);

  const dashboardVelt = useDashboardVeltContext();
  const { getPanelVeltLocation, setVeltLocation } = dashboardVelt;

  const veltLocation = useMemo(
    () => getPanelVeltLocation({ panelId }),
    [panelId],
  );

  useEffect(() => {
    setVeltLocation(veltLocation);
  }, [veltLocation]);

  return (
    <ErrorBoundary
      FallbackComponent={(errorFallbackProps) => <PanelErrorFallbackComponent panelId={panelId} {...errorFallbackProps} />}
    >
      <Box
        className="comment-able"
        data-velt-location={JSON.stringify(veltLocation)}
        ref={panelRef}
        position="relative"
        height="100%"
        width="100%"
        display="flex"
        flexDirection="column"
      >
        <PanelHeader
          panelId={panelId}
          panelParams={panelParams}
          panelTitle={title}
          panelType={panelType}
          serializedInsightsParams={serializedInsightsParams}
        />
        <PanelContent
          panel={panel}
          panelData={hasMorePages ? mappedPanelData.slice(0, -1) : mappedPanelData}
          panelError={panelError}
          isFetching={isFetching}
          serializedInsightsParams={serializedInsightsParams}
          setHasMoreExplorePages={setHasMoreExplorePages}
        />
        <PanelFooter
          panelId={panelId}
          panelParams={panelParams}
          panelError={panelError}
          panelSettings={panelSettings}
          hasData={!!mappedPanelData.length}
          hasMorePages={hasMorePages || hasMoreExplorePages}
          hasSelectedCategory={!!panelSettings?.selectedCategory}
        />
        <div>{isEditingUser ? `${isEditingUser} is editing` : null}</div>
        <LockerOverlay isLocked={isEditingUser} lockedBy={isEditingUser} />
      </Box>
    </ErrorBoundary>
  );
};

export default DashboardPanel;
