import React from 'react';
import AffinityBar from '@/components/AffinityBar';
import EntityImage from '@/components/EntityImage';
import EntityTrends from '@/components/EntityTrends';
import { filterTypes } from '@/constants';
import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import tooltips from '@/utils/tooltips';
import styles from '../DashboardPanel.module.scss';

export const affinityColumn = {
  accessorFn: (row) => (row?.query?.affinity ?? null),
  header: 'Affinity',
  Cell: ({ renderedCellValue }) => (<AffinityBar value={renderedCellValue} />),
  Header: () => <TooltipInfo title={tooltips.dashboard.table.affinity}>Affinity</TooltipInfo>,
  size: 110,
};

export const rankColumn = {
  accessorKey: 'rank',
  header: 'N°',
  Header: () => <TooltipInfo title={tooltips.dashboard.table.rank}>N°</TooltipInfo>,
  Cell: ({ row }) => (
    <div className={styles.tableRank}>
      <EntityImage entity={row.original} />
      <div className={styles.tableRankNumber}>
        <div className={styles.tableRankNumberText}>
          {row.original.rank}
        </div>
      </div>
    </div>
  ),
  size: 40,
};

export const nameColumn = {
  accessorKey: 'name',
  header: 'Name',
  Header: () => <TooltipInfo title={tooltips.dashboard.table.name}>Name</TooltipInfo>,
};

const popularityColumn = {
  accessorKey: 'popularity',
  header: 'Popularity',
  Header: () => <TooltipInfo title={tooltips.dashboard.table.popularity}>Popularity</TooltipInfo>,
  Cell: ({ row }) => (row.original.popularity === 1 ? 1 : parseFloat(row.original.popularity.toFixed(6))),
  size: 40,
};

const trendsColumn = {
  accessorFn: () => null,
  header: 'Trend: Percentile (6m avg)',
  Header: () => <TooltipInfo title={tooltips.dashboard.table.trend}>Trend: Percentile (6m avg)</TooltipInfo>,
  Cell: ({ row }) => <EntityTrends key={row.original.entity_id} entityId={row.original.entity_id} />,
  size: 200,
};

const commonColumns = [
  rankColumn,
  nameColumn,
  affinityColumn,
  trendsColumn,
  popularityColumn,
];

const artistColumns = [
  ...commonColumns,
  {
    accessorFn: (row) => (row?.properties?.date_of_birth ?? null),
    header: 'DOB',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.dateOfBirth}>DOB</TooltipInfo>,
    size: 25,
  },
  {
    accessorFn: (row) => (row?.properties?.place_of_birth ?? null),
    header: 'Place of Birth',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.placeOfBirth}>Place of Birth</TooltipInfo>,
    size: 25,
  },
];

const bookColumns = [
  ...commonColumns,
  {
    accessorFn: (row) => (row?.properties?.publication_year ?? null),
    header: 'Year',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.bookYear}>Year</TooltipInfo>,
    size: 25,
  },
  {
    accessorFn: (row) => (row?.properties?.page_count ?? null),
    header: 'Page Count',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.bookPageCount}>Page Count</TooltipInfo>,
  },
  {
    accessorFn: (row) => (row?.properties?.language ?? null),
    header: 'Language',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.language}>Language</TooltipInfo>,
    size: 25,
  },
  {
    accessorFn: (row) => (row?.properties?.format ?? null),
    header: 'Format',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.bookFormat}>Format</TooltipInfo>,
    size: 25,
  },
  {
    accessorKey: 'disambiguation',
    header: 'Disambiguation',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.bookDisambiguation}>Disambiguation</TooltipInfo>,
  },
];

const brandColumns = [
  ...commonColumns,
  {
    accessorFn: (row) => (row?.properties?.headquartered ?? null),
    header: 'Headquarters',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.headquarters}>Headquarters</TooltipInfo>,
    size: 25,
  },
  {
    accessorFn: (row) => (row?.properties?.inception ?? null),
    header: 'Inception',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.inception}>Inception</TooltipInfo>,
    size: 25,
  },
  {
    accessorFn: (row) => (row?.properties?.official_site ?? null),
    header: 'Official Site',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.url}>Official Site</TooltipInfo>,
    size: 25,
  },
  {
    accessorFn: (row) => {
      const products = row.properties.products || [];
      return products.join(', ');
    },
    header: 'Products',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.products}>Products</TooltipInfo>,
    Cell: ({ cell }) => (
      <div className={styles.tableProducts}>
        {cell.getValue()}
      </div>
    ),
  },
];

const defaultColumns = [
  ...commonColumns,
  { accessorKey: 'disambiguation', header: 'Disambiguation' },
];

const destinationsColumns = [
  ...commonColumns,
  {
    accessorFn: (row) => (row?.properties?.geocode?.admin1_region ?? null),
    header: 'Region 1',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.region1}>Region 1</TooltipInfo>,
    size: 25,
  },
  {
    accessorFn: (row) => (row?.properties?.geocode?.admin2_region ?? null),
    header: 'Region 2',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.region2}>Region 2</TooltipInfo>,
    size: 25,
  },
  {
    accessorFn: (row) => (row?.properties?.geocode?.country_code ?? null),
    header: 'Country Code',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.countryCode}>Country Code</TooltipInfo>,
    size: 25,
  },
];

const peopleColumns = [
  ...commonColumns,
  {
    accessorFn: (row) => (row?.properties?.citizenship ?? null),
    header: 'Citizenship',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.citizenship}>Citizenship</TooltipInfo>,
    size: 25,
  },
  {
    accessorFn: (row) => (row?.properties?.instrument ?? null),
    header: 'Instrument',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.instrument}>Instrument</TooltipInfo>,
    size: 25,
  },
  {
    accessorFn: (row) => (row?.properties?.lifestyle ?? null),
    header: 'Lifestyle',
    size: 25,
  },
  {
    accessorFn: (row) => (row?.properties?.place_of_birth ?? null),
    header: 'Place of Birth',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.placeOfBirth}>Place of Birth</TooltipInfo>,
    size: 25,
  },
  {
    accessorFn: (row) => (row?.properties?.short_description ?? null),
    header: 'Short Description',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.personShortDescription}>Short Description</TooltipInfo>,
    size: 25,
  },
  {
    accessorKey: 'disambiguation',
    header: 'Disambiguation',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.personDisambiguation}>Disambiguation</TooltipInfo>,

  },
];

const placeColumns = [
  ...commonColumns,
  {
    accessorFn: (row) => (row?.properties?.address ?? null),
    header: 'Address',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.placeAddress}>Address</TooltipInfo>,
    size: 25,
  },
  {
    accessorFn: (row) => (row?.properties?.phone ?? null),
    header: 'Phone',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.placePhone}>Phone</TooltipInfo>,
    size: 25,
  },
  {
    accessorFn: (row) => (row?.properties?.business_rating ?? null),
    header: 'Business Rating',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.placeRating}>Business Rating</TooltipInfo>,
    size: 25,
  },
  {
    accessorFn: (row) => (row?.properties?.closed ?? null),
    header: 'Closed',
    size: 25,
  },
  {
    accessorKey: 'disambiguation',
    header: 'Disambiguation',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.placeDisambiguation}>Disambiguation</TooltipInfo>,
  },
];

const tvColumns = [
  ...commonColumns,
  {
    accessorFn: (row) => (row?.properties?.content_rating ?? null),
    header: 'Content Rating',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.tvRating}>Content Rating</TooltipInfo>,
    size: 25,
  },
  {
    accessorFn: (row) => (row?.properties?.duration ?? null),
    header: 'Duration',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.tvDuration}>Duration</TooltipInfo>,
    size: 25,
  },
  {
    accessorFn: (row) => (row?.properties?.release_date ?? null),
    header: 'Release Date',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.releaseDate}>Release Date</TooltipInfo>,
    size: 25,
  },
  {
    accessorFn: (row) => (row?.properties?.finale_year ?? null),
    header: 'Finale Year',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.tvFinaleYear}>Finale Year</TooltipInfo>,
    size: 25,
  },
];

const videogameColumns = [
  ...commonColumns,
  {
    accessorFn: (row) => (row?.properties?.console ?? null),
    header: 'Consoles',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.videoGameConsoles}>Consoles</TooltipInfo>,
    size: 25,
    Cell: ({ row }) => {
      const consoles = row.original.properties?.console || [];
      return consoles.join(', ');
    },
  },
  {
    accessorFn: (row) => (row?.properties?.content_rating ?? null),
    header: 'Content Rating',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.videoGameContentRating}>Content Rating</TooltipInfo>,
    size: 25,
  },
  {
    accessorFn: (row) => (row?.properties?.publisher ?? null),
    header: 'Publisher',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.videoGamePublisher}>Publisher</TooltipInfo>,
    size: 25,
  },
  {
    accessorFn: (row) => (row?.properties?.release_date ?? null),
    header: 'Release Date',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.releaseDate}>Release Date</TooltipInfo>,
    size: 25,
  },
  {
    accessorFn: (row) => (row?.properties?.developer ?? null),
    header: 'Developer',
    Header: () => <TooltipInfo title={tooltips.dashboard.table.videoGameDeveloper}>Developer</TooltipInfo>,
    size: 25,
  },
];

const tagColumns = [
  nameColumn,
  affinityColumn,
];

const columnsMap = {
  [filterTypes.artist]: artistColumns,
  [filterTypes.book]: bookColumns,
  [filterTypes.brand]: brandColumns,
  [filterTypes.destination]: destinationsColumns,
  [filterTypes.person]: peopleColumns,
  [filterTypes.place]: placeColumns,
  [filterTypes.tvShow]: tvColumns,
  [filterTypes.videogame]: videogameColumns,
  [filterTypes.tag]: tagColumns,
};

const getColumns = (filterType) => columnsMap[filterType] || defaultColumns;

export default getColumns;
