const filterTypes = {
  artist: 'urn:entity:artist',
  author: 'urn:entity:author',
  book: 'urn:entity:book',
  brand: 'urn:entity:brand',
  destination: 'urn:entity:destination',
  person: 'urn:entity:person',
  place: 'urn:entity:place',
  podcast: 'urn:entity:podcast',
  tvShow: 'urn:entity:tv_show',
  videogame: 'urn:entity:videogame',
  tag: 'urn:tag',
  heatmap: 'urn:heatmap',
  explore: 'urn:explore', // TODO: remove this
  locationExplorer: 'urn:location_explorer', // TODO: remove this
};

export default filterTypes;
