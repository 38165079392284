import React from 'react';
import { Select, MenuItem, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { filterTypes } from '@/constants';
import DashboardPermissionedControl from '@/components/DashboardPermissionedControl';
import { USER_ROLES } from '@/constants/roles';
import { useActiveDashboard } from '@/hooks';
import { useDashboardActionsContext } from '../DashboardActionsProvider';
import { defaultPageSize, rowSizeOptions } from './helpers/constants';

import styles from './DashboardPanel.module.scss';

const TablePageSizeSelector = ({
  panelId, panelParams, filterType, panelSettings,
}) => {
  const dashboardActions = useDashboardActionsContext();
  const isExploreFilterType = [filterTypes.explore, filterTypes.locationExplorer].includes(filterType);
  const dashboard = useActiveDashboard();

  const handlePageSizeChange = (e) => {
    const nextTake = e.target.value;

    if (isExploreFilterType) {
      dashboardActions.changePanelSettings({
        panelId,
        nextSettings: {
          ...panelSettings,
          take: nextTake,
          offset: 0,
        },
      });

      return;
    }

    dashboardActions.changePanelParams({
      panelId,
      nextParams: {
        ...panelParams,
        take: nextTake,
        offset: 0,
      },
    });
  };

  return (
    <DashboardPermissionedControl
      dashboardId={dashboard.id}
      requiredPermissions={[USER_ROLES.EDITOR]}
    >
      <Box display="flex" gap="8px">
        <Typography fontSize="14px" className={styles.pageSizeLabel}>Rows per page:</Typography>
        <Select
          labelId="page-size"
          id="page-size"
          value={(isExploreFilterType ? panelSettings.take : panelParams.take) || defaultPageSize}
          label="Page Size"
          onChange={handlePageSizeChange}
          size="small"
          variant="standard"
          inputProps={{
            className: styles.pageSizeInput,
          }}
        >
          {rowSizeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </DashboardPermissionedControl>
  );
};

export default TablePageSizeSelector;
