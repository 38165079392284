import React, { useMemo, useState, useEffect } from 'react';
import { findPriority, useCategoryPerms } from '@v1/lib/usePermissions';
import clx from 'clsx';
import { LinearProgress } from '@mui/material';
import { paramNames } from '@/constants';
import tagTypesBySelectedCategory from '@/constants/tagTypesBySelectedCategory';
import { useGetInsightQuery } from '@/store/apis/insightsApi';
import useGPTExplainRecs from '@/hooks/useGPTExplainRecs';
import useGPTExplainCategories from '@/hooks/useGPTExplainCategories';
import { useActiveDashboard } from '@/hooks';
import Overlay from '@/components/Overlay';
import Skeleton from '@/components/Skeleton';
import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import tooltips from '@/utils/tooltips';
import serializeInsightsParams from '@/utils/serializeInsightsParams';
import formatCategoryUrnToCssVar from '@/utils/formatCategoryUrnToCssVar';
import CategoryData from './CategoryData/CategoryData';
import ResumedCategoryData from './ResumedCategory/ResumedCategoryData';
import useTagsFetcher from './useTagsFetcher';
import validateQueryError from '../helpers/validateQueryError';
import PanelBodyError from '../PanelBodyError';
import CategoryDataSkeleton from './CategoryData/CategoryDataSkeleton';
import styles from './PanelBodyExplore.module.scss';

export const CategoryDataView = ({
  category,
  onSelect,
  conciseView,
  isEmptyBaseQuery = false,
  updateCategoryData = () => {},
}) => {
  const { baseParams } = useActiveDashboard();

  const serializedTagsInsightsParams = useMemo(() => {
    const tempParams = {
      [paramNames.filterType]: 'urn:tag',
      [paramNames.filterParentTypes]: category,
      take: 4,
    };

    return serializeInsightsParams(baseParams, tempParams);
  }, [category, baseParams]);

  const {
    data: tagsResult,
    isLoading: isLoadingTags,
    error: tagsError,
  } = useGetInsightQuery(serializedTagsInsightsParams);

  const serializedInsightsParams = useMemo(() => {
    const tempParams = {
      [paramNames.filterType]: category,
      [paramNames.take]: 3,
    };

    return serializeInsightsParams(baseParams, tempParams);
  }, [baseParams, category]);

  const {
    data: entitiesResult,
    isLoading: isLoadingEntities,
    error: insightsError,
  } = useGetInsightQuery(serializedInsightsParams);

  const tagsFetcher = useTagsFetcher();
  const tagTypes = useMemo(() => tagTypesBySelectedCategory[category] || [], [category]);
  const tags = tagsResult?.results?.tags || [];
  const entities = entitiesResult?.results?.entities || [];
  const {
    result: gptResult,
    isLoading: isLoadingGPT,
    error: gptError,
  } = useGPTExplainRecs(
    {
      category, tags, entities, showMini: conciseView,
    },
    {
      skip: isLoadingEntities || isLoadingTags,
    },
  );

  const errorData = useMemo(() => {
    const errors = [insightsError, tagsError, gptError];
    return validateQueryError({ errors });
  }, [insightsError, tagsError, gptError]);

  useEffect(() => {
    if (!isLoadingEntities && !isLoadingTags && !isLoadingGPT) {
      if (gptResult || gptError) {
        updateCategoryData(category, gptResult || '', gptError);
      }
    }
  }, [category, isLoadingEntities, isLoadingTags, isLoadingGPT, gptResult, gptError, updateCategoryData]);

  useEffect(() => {
    if (tagTypes.length) {
      tagsFetcher.fetchByTagTypes({
        baseParams,
        pagination: {
          [paramNames.offset]: 0,
          [paramNames.take]: 9,
        },
        selectedCategory: category,
        tagTypes,
      });
    }
  }, [baseParams, category, tagTypes]);

  if (isLoadingEntities || isLoadingGPT || isLoadingTags) {
    return <CategoryDataSkeleton conciseView={conciseView} />;
  }

  const categoryColor = `var(--category-${formatCategoryUrnToCssVar(category)})`;
  const isEmpty = !gptResult && !tagsResult?.results?.tags?.length && !entitiesResult?.results?.entities?.length;

  if (isEmpty) {
    return null;
  }

  return (
    <>
      {/* removed for now. Until data is correctly returned from the backend */}
      {/*
        {HIGHLIGHT_DATA.some((data) => data.category === category)
          && getTagsForCategory(category).map((tag, index) => {
            return !conciseView ? (
              <CategoryHighlightedData
                baseParams={baseParams}
                category={category}
                onSelect={onSelect}
                tag={tag}
                conciseView={conciseView}
                key={index}
                tagTypes={tagTypes}
                tagsFetcher={tagsFetcher}
              />
            ) : (
              <ResumedCategoryHighlightedData
                baseParams={baseParams}
                category={category}
                onSelect={onSelect}
                tag={tag}
                conciseView={conciseView}
                key={index}
                categoryColor={categoryColor}
                tagTypes={tagTypes}
                tagsFetcher={tagsFetcher}
              />
            );
          })}
        */}

      <TooltipInfo title={tooltips.summaryDetail} followCursor>
        <div>
          {conciseView ? (
            <ResumedCategoryData
              category={category}
              onSelect={onSelect}
              tagsResult={tagsResult}
              entitiesResult={entitiesResult}
              categoryColor={categoryColor}
              error={errorData}
              gptResult={gptResult}
              tagTypes={tagTypes}
              tagsFetcher={tagsFetcher}
            />
          ) : (
            <CategoryData
              category={category}
              onSelect={onSelect}
              tagsResult={tagsResult}
              entitiesResult={entitiesResult}
              gptResult={gptResult}
              error={errorData}
              isEmptyBaseQuery={isEmptyBaseQuery}
              tagTypes={tagTypes}
              tagsFetcher={tagsFetcher}
            />
          )}
        </div>
      </TooltipInfo>
    </>
  );
};

const CategoriesDataList = ({
  onSelect,
  visible,
  panel,
  isLocationExplorer = true,
}) => {
  const { baseParams } = useActiveDashboard();
  const { params: panelParams } = panel;

  const categories = Object.keys(useCategoryPerms()).sort((a, b) => {
    const aPriority = findPriority(a);
    const bPriority = findPriority(b);
    if (aPriority !== bPriority) {
      return aPriority - bPriority;
    }
    return a.localeCompare(b);
  });

  const [categoriesData, setCategoriesData] = useState(
    categories.map((category) => ({
      category,
      loaded: false,
      gptResult: '',
    })),
  );

  const updateCategoryData = (category, gptResult, error) => {
    setCategoriesData((prev) => {
      const existingCategory = prev.find((data) => data.category === category);
      if (existingCategory?.loaded) {
        return prev;
      }
      return prev.map((data) => (data.category === category
        ? { ...data, loaded: true, gptResult: error ? '' : gptResult }
        : data));
    });
  };

  const loadedCount = categoriesData.filter((data) => data.loaded).length;
  const totalCategories = categoriesData.length;
  const allLoaded = loadedCount === totalCategories;
  const gptCategoriesData = useMemo(() => {
    if (allLoaded) {
      return categoriesData.map((data) => data.gptResult);
    }
    return [];
  }, [allLoaded, categoriesData]);

  const {
    result: gptResult,
    isLoading: isLoadingGPT,
  } = useGPTExplainCategories(
    {
      categories: gptCategoriesData,
    },
    {
      skip: !allLoaded,
    },
  );

  const isEmptyBaseQuery = useMemo(
    () => Object.keys(baseParams).length === 0
      || Object.values(baseParams).every(
        (value) => (Array.isArray(value) && value.length === 0) || !value,
      ),
    [baseParams],
  );
  const isEmptyPanelParamsQuery = useMemo(() => {
    const signalLocationValue = panelParams?.['signal.location']?.value;
    const hasValidSignalLocation = typeof signalLocationValue === 'string' && signalLocationValue?.trim()?.length > 0;
    const hasOmnisearch = panelParams?.omnisearch?.length > 0;
    return !(hasValidSignalLocation || hasOmnisearch);
  }, [panelParams]);

  return (
    <div
      className={clx(
        styles.mainContainer,
        { [styles.hidden]: !visible },
      )}
    >
      {!allLoaded && (
        <Overlay>
          <LinearProgress />
          <div className={styles.progressBarContainer}>
            <div className={styles.progressBar}>
              Loading {loadedCount} of {totalCategories}
            </div>
          </div>
        </Overlay>
      )}
      {allLoaded && (
        <span className={styles.gptMessage}>
          {isLoadingGPT && (
            <Skeleton
              width="95%"
              height={40}
            />
          )}
          {!isLoadingGPT && gptResult}
        </span>
      )}

      <div
        className={clx(
          styles.categoriesDataListGrid,
          styles.categoriesDataListGridConcise,
        )}
      >
        {categories.map((category) => (
          <CategoryDataView
            category={category}
            key={category}
            onSelect={onSelect}
            conciseView
            isEmptyBaseQuery={!Object.keys(baseParams).length}
            updateCategoryData={updateCategoryData}
          />
        ))}
      </div>
      {isEmptyBaseQuery && isEmptyPanelParamsQuery && (
        <Overlay>
          <PanelBodyError
            message="At least one base parameter is required to show the entities response."
            className={isLocationExplorer ? styles.panelBodyErrorToExploreLocation : ''}
          />
        </Overlay>
      )}
    </div>
  );
};

export default CategoriesDataList;
