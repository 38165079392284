import React from 'react';
import { Box, Chip } from '@mui/material';
import { PERMISSION_LABELS, USER_ROLES } from '@/constants/roles';
import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import tooltips from '@/utils/tooltips';

const PermissionChips = ({ isUserOwner, role }) => {
  return (
    <TooltipInfo title={tooltips.permission(isUserOwner, role)}>
      <Box display="flex" gap={1} flexWrap="wrap" alignItems="center">
        <Chip
          label={isUserOwner ? PERMISSION_LABELS[USER_ROLES.OWNER] : PERMISSION_LABELS[role]}
          variant="outlined"
          color="secondary"
          size="small"
          disabled
        />
      </Box>
    </TooltipInfo>

  );
};

export default PermissionChips;
