import React, { useEffect } from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, ListItemIcon, ListItemText, MenuItem,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LoadingButton from '@mui/lab/LoadingButton';
import FeatureNoAccessTooltip from '@/screens/HomePage/FeatureNoAccessTooltip';
import { useDeleteDashboardMutation } from '@/store/apis/dashboardsApi';
import { useToggle } from '@/hooks';
import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import tooltips from '@/utils/tooltips';

const DeleteDialog = ({
  dashboard, open, onClose, onDeleted,
}) => {
  const { dashboardId, id } = dashboard;

  const [deleteDashboard, { isLoading: isDeleting, isSuccess: isDeleted }] = useDeleteDashboardMutation();

  const handleSubmit = () => {
    if (onDeleted) {
      onDeleted();
    }
    deleteDashboard({ dashboardId, id });
  };

  useEffect(() => {
    if (isDeleted) {
      onClose();
    }
  }, [isDeleted, onClose]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        Are you sure you want to delete?
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancel</Button>
        <LoadingButton
          onClick={handleSubmit}
          loading={isDeleting}
          variant="contained"
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const MenuItemDelete = ({
  dashboard, disabled, onDeleted, ...props
}) => {
  const isOpenToggle = useToggle();

  const handleClose = () => {
    isOpenToggle.off();
  };

  return (
    <FeatureNoAccessTooltip disabled={disabled}>
      <TooltipInfo title={tooltips.dashboardCard.delete} followCursor>
        <MenuItem onClick={isOpenToggle.on} disabled={disabled} {...props}>
          <ListItemIcon><DeleteOutlineIcon /></ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </TooltipInfo>
      <DeleteDialog dashboard={dashboard} open={isOpenToggle.value} onClose={handleClose} onDeleted={onDeleted} />
    </FeatureNoAccessTooltip>
  );
};

export default MenuItemDelete;
