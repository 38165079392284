import React from 'react';
import Table from '@/components/Table';

import { useDashboardActionsContext } from '../DashboardActionsProvider';
import getColumns from './helpers/getColumns';
import styles from './DashboardPanel.module.scss';

const PanelBodyTable = ({
  data,
  panelId,
  filterType,
  panelSettings,
  isFetching,
}) => {
  const dashboardActions = useDashboardActionsContext();
  const columns = getColumns(filterType);

  const handleSortChange = (nextSort) => {
    const nextSettings = {
      ...panelSettings,
      sorting: nextSort(),
    };

    dashboardActions.changePanelSettings({ panelId, nextSettings });
  };

  const tableData = data && data?.length ? data : [];

  return (
    <div className={styles.tableContainer}>
      <Table
        isLoading={isFetching}
        columns={columns}
        data={tableData}
        getRowId={({ row }) => row.entity_id}
        onSortingChange={handleSortChange}
        renderEmptyRowsFallback={() => <div />}
        sorting={panelSettings?.sorting}
      />
    </div>
  );
};

export default PanelBodyTable;
