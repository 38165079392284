import React, { useMemo } from 'react';
import Tag from '@/components/Tag';
import { paramNames } from '@/constants';
import { affinityColumn, nameColumn } from '@/screens/Dashboard/DashboardPanel/helpers/getColumns';
import { useGetInsightQuery } from '@/store/apis/insightsApi';
import serializeInsightsParams from '@/utils/serializeInsightsParams';
import { useActiveDashboard } from '@/hooks';
import TableWithTitle from '../TableWithTitle';
import { defaultPageOffset, defaultPageSize } from '../../helpers/constants';

const TagCell = ({ row, selectedCategory }) => {
  return (
    <Tag
      noBorder
      noHover
      key={row.tagId}
      useCategoryColor
      {...row.original}
      isSelected={false}
      showSelectedIcon={false}
      type={row?.original?.subtype}
      category={selectedCategory}
    />
  );
};

const SelectedCategoryContentTagsSingle = ({
  onSpecificDataSelect,
  panel,
  selectedCategory,
  selectedData,
  highlightedSelectedCategory,
}) => {
  const { baseParams } = useActiveDashboard();
  const { params: panelParams, settings: panelSettings } = panel;

  const serializedTagsInsightsParams = useMemo(() => {
    const {
      [paramNames.filterLocation]: filterLocation,
      ...restPanelParams
    } = panelParams;

    const tempParams = {
      ...restPanelParams,
      [paramNames.signalLocation]: filterLocation || restPanelParams[paramNames.signalLocation],
      [paramNames.filterType]: 'urn:tag',
      [paramNames.filterParentTypes]: selectedCategory,
      offset: panelSettings?.offset || defaultPageOffset,
      take: (panelSettings?.take || defaultPageSize) + 1,
    };

    if (highlightedSelectedCategory) {
      tempParams[paramNames.filterTags] = { [highlightedSelectedCategory]: 'include' };
    }

    return serializeInsightsParams(baseParams, tempParams);
  }, [baseParams, panelSettings, panelParams, selectedCategory, highlightedSelectedCategory]);

  const {
    data: tagsResult,
    isLoading: isTagsLoading,
    error: insightsTagsError,
  } = useGetInsightQuery(serializedTagsInsightsParams, {
    skip: selectedData === 'ENTITIES',
  });

  const tags = useMemo(
    () => (insightsTagsError ? [] : tagsResult?.results?.tags.slice(0, panelSettings?.take || defaultPageSize)),
    [tagsResult?.results?.tags, insightsTagsError, panelSettings],
  );

  return (
    <TableWithTitle
      isLoading={isTagsLoading}
      title="Tags"
      columns={[
        {
          ...nameColumn,
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (props) => <TagCell {...props} selectedCategory={selectedCategory} />,
        },
        affinityColumn,
      ]}
      data={tags || []}
      onSelect={onSpecificDataSelect(selectedData === 'TAGS' ? null : 'TAGS')}
    />
  );
};

export default SelectedCategoryContentTagsSingle;
