import React from 'react';

import Avatar from '@mui/material/Avatar';
import makeStyles from '@mui/styles/makeStyles';
import userInformation from '../../lib/userInformation';
import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import tooltips from '@/utils/tooltips';

export const useStyles = makeStyles(() => ({
  profileButtonContainer: {
    background: 'none',
    border: 0,
    paddingRight: '0 !important',
    display: 'inline-block',
  },
  profileButton: {
    border: '2px solid white',
    // TODO_SUPER: There is nothing to click on for the user so disable the
    // pointer for now.
    // cursor: 'pointer',
  },

}));

const UserBadge = () => {
  const classes = useStyles();
  const user = userInformation();
  const fullName = `${user?.firstName} ${user?.lastName}`;
  const initials = `${user?.firstName?.[0] || '' }${user?.lastName?.[0] || ''}`;
  return (
    <button
      type="button"
      className={classes.profileButtonContainer}
    >
      <TooltipInfo title={tooltips.userProfile(fullName)}>
        <Avatar
          className={classes.profileButton}
          alt={fullName}
          src={user?.profilePictureUrl}
        >{initials}
        </Avatar>
      </TooltipInfo>
    </button>
  );
};

export default UserBadge;
