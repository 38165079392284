/// The react/jsx-no-useless-fragment is not accurate because there are cases
/// where a fragment contains more than one child even though it looks like
/// it only has one child. we should at some point remove this rule from our
/// eslint configuration.

/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import TooltipWarning from '@/components/Tooltip/TooltipWarning';

const FeatureNoAccessTooltip = ({ children, disabled }) => {
  return (
    <TooltipWarning
      conditionalRender={disabled}
      placement="top"
      message="You don't have permission to access this feature."
    >
      <>
        {children}
      </>
    </TooltipWarning>
  );
};

export default FeatureNoAccessTooltip;
