import React, { useEffect, useMemo } from 'react';
import clsx from 'clsx';

import { paramNames } from '@/constants';
import panelTypes from '@/constants/panelTypes';
import FetchingOverlay from '@/components/Overlay/FetchingOverlay';
import Overlay from '@/components/Overlay';
import PanelBodyTable from './PanelBodyTable';
import PanelBodyHeatmap from './PanelBodyHeatmap';
import PanelBodyExplore from './PanelBodyExplore';
import PanelBodyLocationExplorer from './PanelBodyLocationExplorer';
import PanelBodyError from './PanelBodyError';
import getIsInsightPanel from './helpers/getIsInsightPanel';
import { getHumanizedQueryError } from '../FilterTags/helpers';
import styles from './DashboardPanel.module.scss';

const ErrorOverlay = ({ error }) => (
  <Overlay>
    <PanelBodyError error={error} />
  </Overlay>
);

const PanelBodyWrapper = ({
  serializedInsightsParams,
  panelError,
  panel,
  ...props
}) => {
  const { panelId, type: panelType } = panel;
  const error = getHumanizedQueryError(panelError);
  const hasError = Boolean(error);
  const hasData = Boolean(props.panelData.length);

  const panelBodyId = useMemo(() => `dashboardPanelBody-${panelId}`, [panelId]);

  useEffect(() => {
    if (!props.isFetching) {
      const panelBody = document.getElementById(panelBodyId);
      panelBody.scrollTop = 0;
    }
  }, [props.isFetching, panelBodyId, serializedInsightsParams]);

  const isInsightPanel = getIsInsightPanel(panelType);

  return (
    <div
      className={clsx({
        [styles.panelBodyContainer]: true,
        [styles.explorePanel]: !isInsightPanel,
      })}
    >
      <div
        id={panelBodyId}
        className={clsx({
          [styles.dashboardPanelBody]: isInsightPanel,
          [styles.explorePanel]: !isInsightPanel,
        })}
      >
        <PanelBody panel={panel} {...props} />
        {(hasError || (!hasData && isInsightPanel)) && (
          <ErrorOverlay error={error} />
        )}
        {props.isFetching && <FetchingOverlay />}
      </div>
    </div>
  );
};

const PanelBody = ({
  panel,
  panelData,
  setHasMoreExplorePages,
  isFetching,
}) => {
  const {
    panelId,
    params: panelParams,
    settings: panelSettings,
    type: panelType,
  } = panel;

  if (panelType === panelTypes.heatmap) {
    return (
      <PanelBodyHeatmap
        data={panelData}
        panelId={panelId}
        panelSettings={panelSettings}
        isFetching={isFetching}
      />
    );
  }

  if (panelType === panelTypes.explore) {
    return (
      <PanelBodyExplore
        panel={panel}
        setHasMoreExplorePages={setHasMoreExplorePages}
      />
    );
  }

  if (panelType === panelTypes.locationExplorer) {
    return (
      <PanelBodyLocationExplorer
        panel={panel}
        setHasMoreExplorePages={setHasMoreExplorePages}
      />
    );
  }

  return (
    <PanelBodyTable
      data={panelData}
      panelId={panelId}
      filterType={panelParams[paramNames.filterType]}
      panelSettings={panelSettings}
      isFetching={isFetching}
    />
  );
};

export default PanelBodyWrapper;
