import React from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { useToggle } from '@/hooks';
import FeatureNoAccessTooltip from '@/screens/HomePage/FeatureNoAccessTooltip';
import ShareWithDialog from './ShareWith/ShareWithDialog';
import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import tooltips from '@/utils/tooltips';

const MenuItemShare = ({
  dashboard, disabled, ...props
}) => {
  const isOpenToggle = useToggle();

  return (
    <FeatureNoAccessTooltip disabled={disabled}>
      <TooltipInfo title={tooltips.dashboardCard.share} followCursor>
        <MenuItem onClick={isOpenToggle.on} disabled={disabled} {...props}>
          <ListItemIcon><PersonAddAltIcon /></ListItemIcon>
          <ListItemText>Share</ListItemText>
        </MenuItem>
      </TooltipInfo>
      <ShareWithDialog
        dashboard={dashboard}
        open={isOpenToggle.value}
        onClose={isOpenToggle.off}
      />
    </FeatureNoAccessTooltip>
  );
};

export default MenuItemShare;
