import React from 'react';
import { X } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Tooltip, Typography, useTheme, IconButton as MuiIconButton,
} from '@mui/material';
import TabButton from '@/components/TabButton';
import { closeDashboardById } from '@/store/slices/dashboardTabsSlice';
import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import tooltips from '@/utils/tooltips';
import getTitle from '../helpers/getTitle';

import styles from './DashboardTabs.module.scss';

const getNextIndex = (index, length) => {
  if (index + 1 < length) {
    return index + 1;
  }

  if (index - 1 >= 0) {
    return index - 1;
  }

  return null;
};

const DashboardTab = ({
  id, dashboard, dashboardIds, isSelected,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleNavigate = (e) => {
    e.preventDefault();
    if (!isSelected) {
      navigate(`/dashboards/view/${id}`);
    }
  };

  const handleDelete = async (e) => {
    e.stopPropagation();

    dispatch(closeDashboardById(id));

    if (isSelected) {
      const currentIndex = dashboardIds.indexOf(id);
      const nextIndex = getNextIndex(currentIndex, dashboardIds.length - 1);
      if (typeof nextIndex === 'number' && dashboardIds[nextIndex]) {
        navigate(`/dashboards/view/${dashboardIds[nextIndex]}`);
      } else {
        navigate('/dashboards/view/');
      }
    }
  };

  const title = getTitle(dashboard);

  return (
    <TabButton
      onClick={handleNavigate}
      color={isSelected ? 'default' : 'muted'}
      sx={{
        flex: 1,
        borderRight: `1px solid ${theme.palette.divider}`,
        borderTop: `1px solid ${theme.palette.divider}`,
        minWidth: '100px',
      }}
    >
      <TooltipInfo title={tooltips.dashboard.x}>
        <div>
          <MuiIconButton
            component="span"
            onClick={handleDelete}
          >
            <X size={12} color="#000" />
          </MuiIconButton>
        </div>
      </TooltipInfo>
      <Tooltip title={title} placement="top">
        <Typography variant="body1" className={styles.title}>{title}</Typography>
      </Tooltip>
    </TabButton>
  );
};

export default DashboardTab;
