import React, { useCallback, useMemo } from 'react';
import InlineButton from '@/components/InlineButton';
import { paramNames, popovers } from '@/constants';
import getIsFieldValueEmpty from '@/utils/getIsFieldValueEmpty';
import { useDescribeLocation } from '@/hooks';

import DemographicsLabel from './DemographicsLabel';
import LocationParam from '../LocationParam/LocationParam';

import getInterestsLabels from '../helpers/getInterestsLabel';
import { demographicsParamNames } from '../constants';
import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import tooltips from '@/utils/tooltips';

const QueryBuilder = ({
  children, params, onOpen, veltLocation,
}) => {
  const describedLocation = useDescribeLocation(params[paramNames.signalLocation]);

  const interestsLabel = useMemo(() => getInterestsLabels(params), [params]);

  const DemographicsComponent = useCallback(({ color, className }) => (
    <TooltipInfo title={tooltips.dashboard.anyone} followCursor>
      <div>
        <InlineButton
          color={color}
          className={className}
          onClick={onOpen(popovers.demographics)}
        >
          <DemographicsLabel params={params} />
        </InlineButton>
      </div>
    </TooltipInfo>
  ), [onOpen, params]);

  const LocationComponent = useCallback(({ color, className }) => (
    <TooltipInfo title={tooltips.dashboard.anywhere} followCursor>
      <div>
        <LocationParam
          color={color}
          className={className}
          location={describedLocation}
          onClick={onOpen(popovers.locations)}
        />
      </div>
    </TooltipInfo>
  ), [describedLocation]);

  const InterestsComponent = useCallback(({ color, className }) => (
    <TooltipInfo title={tooltips.dashboard.anything} followCursor>
      <div>
        <InlineButton
          color={color}
          className={className}
          onClick={onOpen(popovers.interests)}
        >
          {interestsLabel}
        </InlineButton>
      </div>
    </TooltipInfo>
  ), [interestsLabel]);

  return (
    <div data-velt-location={JSON.stringify(veltLocation)}>
      {children({
        hasDemographics: demographicsParamNames.find((paramName) => !getIsFieldValueEmpty(params[paramName])),
        hasInterests: interestsLabel !== 'Anything',
        hasLocation: Boolean(describedLocation?.value),

        demographicsLabel: <DemographicsLabel params={params} />,
        locationLabel: describedLocation?.displayLabel,
        interestsLabel,

        openLocationPopover: onOpen(popovers.locations),
        openDemographicsPopover: onOpen(popovers.demographics),
        openInterestsPopover: onOpen(popovers.interests),

        DemographicsComponent,
        LocationComponent,
        InterestsComponent,
      })}
    </div>
  );
};

export default QueryBuilder;
