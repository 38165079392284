import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@/components/Button';
import { useCreateDashboardMutation } from '@/store/apis/dashboardsApi';
import TooltipWarning from '@/components/Tooltip/TooltipWarning';
import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import tooltips from '@/utils/tooltips';

const DashboardCreateButton = ({ isAuthorized, setCreateError }) => {
  const navigate = useNavigate();
  const [createDashboard, { isLoading, isError }] = useCreateDashboardMutation();

  const handleCreate = async () => {
    const { data } = await createDashboard();
    if (isError) {
      setCreateError(true);
      return;
    }
    navigate(`/dashboards/view/${data.id}`);
  };

  return (
    <TooltipWarning placement="top-start" message={tooltips.newDashboard} conditionalRender={!isAuthorized}>
      <div>
        <TooltipInfo title="Create a new dashboard">
          <div>
            <Button
              onClick={handleCreate}
              disabled={isLoading || !isAuthorized}
              isLoading={isLoading}
              variant="contained"
            >
              New Dashboard
            </Button>
          </div>
        </TooltipInfo>
      </div>
    </TooltipWarning>
  );
};

export default DashboardCreateButton;
