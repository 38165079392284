import React from 'react';
import {
  IconButton, ListItemIcon, ListItemText, Menu, MenuItem,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import useHasPermission from '@v1/hooks/useHasPermission';
import { api } from '@/constants';
import { useActiveDashboard, useUserDashboardPermissions } from '@/hooks';
import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import tooltips from '@/utils/tooltips';
import { useDashboardActionsContext } from '../../DashboardActionsProvider';
import MenuItemExportData from './MenuItemExportData';
import MenuItemRename from './MenuItemRename';
import MenuItemColor from './MenuItemColor';
import getIsInsightPanel from '../helpers/getIsInsightPanel';

const PanelContextMenu = ({
  panelId, panelTitle, panelType, serializedInsightsParams,
}) => {
  const dashboard = useActiveDashboard();
  const { hasRenamePermission } = useUserDashboardPermissions(dashboard.id);
  const dashboardActions = useDashboardActionsContext();
  const hasExportPermission = useHasPermission('feature.dashboard.panel.export');

  const menuPopupState = usePopupState({
    variant: 'popper',
    popupId: 'menuPopupState',
  });

  const handlePanelDelete = () => {
    dashboardActions.deletePanel(panelId);
  };

  const handlePanelClone = () => {
    dashboardActions.clonePanel(panelId);
  };

  const handleOpenInRequestBrowser = () => {
    const requestUrl = `${api.server}/v2/insights?${serializedInsightsParams}`;
    window.open(
      `/#/requests?requests=${encodeURIComponent(requestUrl)}`,
      '_blank',
    );
  };

  const isInsightPanel = getIsInsightPanel(panelType);

  return (
    <>
      <TooltipInfo title={tooltips.dashboard.moreActions} followCursor>
        <IconButton aria-label="panel-menu" {...bindTrigger(menuPopupState)}>
          <MoreHorizIcon color="secondary" />
        </IconButton>
      </TooltipInfo>
      <Menu {...bindMenu(menuPopupState)}>
        <MenuItemRename panelId={panelId} panelTitle={panelTitle} disabled={!hasRenamePermission} />
        <TooltipInfo title={tooltips.dashboard.clonePanel} followCursor>
          <MenuItem onClick={handlePanelClone}>
            <ListItemIcon><FileCopyOutlinedIcon /></ListItemIcon>
            <ListItemText>Clone Panel</ListItemText>
          </MenuItem>
        </TooltipInfo>
        {isInsightPanel && (
          <TooltipInfo title={tooltips.dashboard.openInRequestBrowser} followCursor>
            <MenuItem onClick={handleOpenInRequestBrowser}>
              <ListItemIcon><OpenInNewIcon /></ListItemIcon>
              <ListItemText>Open in request browser</ListItemText>
            </MenuItem>
          </TooltipInfo>
        )}
        <TooltipInfo title={tooltips.dashboard.delete} followCursor>
          <MenuItem onClick={handlePanelDelete}>
            <ListItemIcon><DeleteOutlineIcon /></ListItemIcon>
            <ListItemText>Delete Panel</ListItemText>
          </MenuItem>
        </TooltipInfo>
        {(isInsightPanel && hasExportPermission) && (
          <TooltipInfo title={tooltips.dashboard.export} followCursor>
            <MenuItemExportData
              panelId={panelId}
              serializedInsightsParams={serializedInsightsParams}
              onDownloaded={menuPopupState.close}
            />
          </TooltipInfo>
        )}
        <MenuItemColor panelId={panelId} parentMenuState={menuPopupState} />
      </Menu>
    </>
  );
};

export default PanelContextMenu;
