import React from 'react';
import { paramNames } from '@/constants';
import TablePageSizeSelector from './TablePageSizeSelector';
import TablePagination from './TablePagination';

import styles from './DashboardPanel.module.scss';

const PanelFooterTable = ({
  panelId, panelParams, hasMorePages, panelSettings,
}) => {
  const filterType = panelParams[paramNames.filterType];
  return (
    <div className={styles.panelFooter}>
      <TablePageSizeSelector
        panelId={panelId}
        panelParams={panelParams}
        filterType={filterType}
        panelSettings={panelSettings}
      />
      <TablePagination
        panelId={panelId}
        filterType={filterType}
        panelParams={panelParams}
        hasMorePages={hasMorePages}
        panelSettings={panelSettings}
      />
      <div />
    </div>
  );
};

export default PanelFooterTable;
