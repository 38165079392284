import React from 'react';
import { filterTypes, paramNames } from '@/constants';
import isTableFilterType from '@/utils/isTableFilterType';
import PanelFooterTable from './PanelFooterTable';
import { getQueryError } from '../FilterTags/helpers';
import styles from './DashboardPanel.module.scss';

const PanelFooter = ({
  panelId,
  panelParams,
  panelError,
  panelSettings,
  hasMorePages,
  hasData,
  hasSelectedCategory,
}) => {
  const filterType = panelParams?.[paramNames.filterType];
  const error = getQueryError(panelError);
  const hasError = Boolean(error);
  const isExploreFilterType = [filterTypes.explore, filterTypes.locationExplorer].includes(filterType);
  const isTable = isTableFilterType(filterType);

  if (
    (isTable && hasData && !hasError && (hasMorePages || panelParams.offset > 0))
    || (isExploreFilterType && hasSelectedCategory && (hasMorePages || panelSettings.offset > 0))
  ) {
    return (
      <PanelFooterTable
        panelId={panelId}
        panelParams={panelParams}
        hasMorePages={hasMorePages}
        panelSettings={panelSettings}
      />
    );
  }

  // Empty footer
  return (
    <div className={styles.panelFooter} />
  );
};

export default PanelFooter;
