import {
  Box, Button, ButtonGroup, Collapse,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import { useToggle } from '@/hooks';

import HeatmapToggle from './HeatmapToggle';
import HeatmapRangeSlider from './HeatmapRangeSlider';
import HeatmapOpacitySlider from './HeatmapOpacitySlider';

const HeatmapControlsWrapper = ({ isCollapsible, ...props }) => {
  const controlsToggle = useToggle();

  return (
    <Box
      position="absolute"
      top="8px"
      left={isCollapsible ? 'auto' : '8px'}
      right="8px"
      display="flex"
      gap="10px"
      justifyContent={isCollapsible ? 'flex-end' : 'flex-start'}
      overflow="auto"
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Collapse orientation="horizontal" in={!isCollapsible || controlsToggle.value}>
        <HeatmapControls {...props} />
      </Collapse>
      {isCollapsible && (
        <Button
          onClick={controlsToggle.toggle}
          variant="map"
          color="secondary"
          selected={controlsToggle.value}
          size="small"
          disableElevation
          noPadding
        >
          <WhatshotIcon fontSize="small" />
        </Button>
      )}
    </Box>
  );
};

const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '4px',
  margin: '2px',
  height: '29px',
  boxShadow: '0 0 0 2px rgba(0, 0, 0, .1)',
  overflow: 'hidden',

  '& .MuiButtonGroup-grouped:not(:last-of-type)': {
    borderColor: theme.palette.gray.main,
  },
}));

const HeatmapControls = ({ panelSettings, onControlsChange }) => {
  return (
    <Container>
      <ButtonGroup
        color="secondary"
        variant="text"
        sx={{ height: '100%' }}
      >
        <HeatmapToggle value={panelSettings.heatmapType} onChange={onControlsChange('heatmapType')} />
        <Button disableRipple noPadding sx={{ cursor: 'default' }}>
          <HeatmapRangeSlider value={panelSettings.heatmapRange} onChange={onControlsChange('heatmapRange')} />
        </Button>
        <Button disableRipple noPadding sx={{ cursor: 'default' }}>
          <HeatmapOpacitySlider value={panelSettings.heatmapOpacity} onChange={onControlsChange('heatmapOpacity')} />
        </Button>
      </ButtonGroup>
    </Container>
  );
};

export default HeatmapControlsWrapper;
