import React from 'react';
import clsx from 'clsx';
import getPanelHeaderTitle from '@/utils/getPanelHeaderTitle';
import DashboardPermissionedControl from '@/components/DashboardPermissionedControl';
import { USER_ROLES } from '@/constants/roles';
import { useActiveDashboard } from '@/hooks';
import { useDashboardVeltContext } from '../DashboardVeltProvider';

import PanelContextMenu from './PanelContextMenu';
import PanelParams from './PanelParams';
import styles from './DashboardPanel.module.scss';

const PanelHeader = ({
  panelId, panelParams, panelTitle, panelType, serializedInsightsParams,
}) => {
  const dashboard = useActiveDashboard();
  const dashboardVelt = useDashboardVeltContext();
  const { isCommentingToggle } = dashboardVelt;

  return (
    <div className={styles.panelHeaderWrapper}>
      <div className={styles.panelHeader}>
        <div
          className={clsx({
            'panel-drag-handle': !isCommentingToggle.value,
            [styles.panelHeaderLeft]: true,
          })}
          data-velt-sync-access="true"
        >
          <span>{panelTitle || getPanelHeaderTitle(panelType)}</span>
        </div>

        <DashboardPermissionedControl
          dashboardId={dashboard.id}
          requiredPermissions={[USER_ROLES.EDITOR]}
        >
          <div className={styles.panelHeaderRight}>
            <PanelContextMenu
              panelId={panelId}
              panelTitle={panelTitle}
              serializedInsightsParams={serializedInsightsParams}
              panelType={panelType}
            />
          </div>
        </DashboardPermissionedControl>
      </div>
      <PanelParams
        panelType={panelType}
        panelId={panelId}
        panelParams={panelParams}
      />
    </div>
  );
};

export default PanelHeader;
