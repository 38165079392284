import ageRangeLabels from './ageRangeLabels';
import getAgeLabel from '../utils/getAgeLabel';
import fieldTypes from './fieldTypes';
import { paramNames } from './paramNames';
import placeBrands from './permissions/placeBrands';

const fieldByParamName = {
  [paramNames.filterEntities]: {
    title: 'Show only these entities',
    label: 'Entities',
    type: fieldTypes.entitiesSearch,
    renderTag: (value) => value.props.value.map((v) => v.name).join(', '),
  },
  [paramNames.filterExternalExists]: {
    label: 'External Exists',
    options: [
      { value: 'resy', label: 'Resy' },
      { value: 'michelin', label: 'Michelin' },
      { value: 'tablet', label: 'Tablet' },
    ],
    type: fieldTypes.checkboxList,
  },
  [paramNames.filterPopularity]: {
    label: 'Popularity',
    type: fieldTypes.slider,
    min: 0,
    max: 1,
    defaultValue: [0, 1],
    step: 0.01,
    withInput: true,
    size: 'small',
    marks: [
      { value: 0, label: 'less' },
      { value: 0.5, label: 'medium' },
      { value: 1, label: 'high' },
    ],
    renderTag: (value) => {
      if (!value || !value.props || !value.props.value || !Array.isArray(value.props.value)) {
        return ' ';
      }
      const [min, max] = value?.props?.value || [];

      if (min === max) {
        return min;
      }

      return `${min} - ${max}`;
    },
  },
  [paramNames.filterPrice_level]: {
    label: 'Price',
    type: fieldTypes.ratingRange,
    defaultValue: [1, 4],
    max: 4,
    useMoneyIcon: true,
    renderTag: (value) => {
      if (!value || !value.props || !value.props.value || !Array.isArray(value.props.value)) {
        return ' ';
      }

      const minValue = Number(value.props.value[0]) || 0;
      const maxValue = Number(value.props.value[1]) || 0;

      const minPriceLevel = minValue > 0 ? '$'.repeat(minValue) : '';
      const maxPriceLevel = maxValue > 0 ? '$'.repeat(maxValue) : '';

      if (minPriceLevel && maxPriceLevel) {
        if (minPriceLevel === maxPriceLevel) {
          return minPriceLevel;
        }

        return `${minPriceLevel} - ${maxPriceLevel}`;
      }
      if (minPriceLevel) {
        return `${minPriceLevel}`;
      }
      if (maxPriceLevel) {
        return `${maxPriceLevel}`;
      }

      return ' ';
    },
  },
  [paramNames.filterRating]: {
    label: 'Rating',
    type: fieldTypes.ratingRange,
    defaultValue: [0, 5],
    renderTag: (value) => {
      if (!value || !value.props || !value.props.value || !Array.isArray(value.props.value)) {
        return ' ';
      }

      const [min, max] = value?.props?.value || [];

      if (min === max) {
        return min;
      }

      return `${min} - ${max}`;
    },
  },
  [paramNames.filterType]: {
    label: 'Category',
    type: fieldTypes.select,
  },
  [paramNames.omnisearch]: {
    label: 'Interests',
    type: fieldTypes.omnisearch,
  },
  [paramNames.signalDemographicsAge]: {
    label: 'Age',
    options: ageRangeLabels,
    type: fieldTypes.checkboxList,
    renderTag: (value) => getAgeLabel(value, ageRangeLabels.length),
  },
  [paramNames.signalDemographicsGender]: {
    label: 'Gender',
    options: [{ value: 'male', label: 'Male' }, { value: 'female', label: 'Female' }],
    type: fieldTypes.radio,
  },
  [paramNames.audiences]: {
    type: fieldTypes.audiences,
    label: 'Audiences',
    // TODO: use new api to retrieve audience metadata
    renderTag: (value) => Object.keys(value).join(', '),
  },
  [paramNames.signalLocation]: {
    type: fieldTypes.location,
  },
  [paramNames.filterTags_status]: {
    type: fieldTypes.filterTags,
  },
  [paramNames.filterParentTypes]: {
    type: fieldTypes.filterTagsParents,
  },
  [paramNames.filterTags]: {
    type: fieldTypes.filterTags,
  },
  [paramNames.filterLocation]: {
    label: 'Location',
    type: fieldTypes.location,
  },
  [paramNames.offset]: {
  },
  [paramNames.take]: {},
  [paramNames.filterTagTypes]: {},
  [paramNames.filterReferencesBrand]: {
    label: 'Place Brand',
    type: fieldTypes.placeBrand,
    renderTag: (values) => {
      return values.props.value.map((pb) => {
        return placeBrands.find((placeBrand) => placeBrand.value === pb.value).label;
      }).join(', ');
    },
  },
};

export default fieldByParamName;
